<template>
  <form @submit.prevent="onSearch()">
    <v-text-field
      v-model="search"
      color="primary"
      outlined
      dense
      placeholder="ค้นหา"
      hide-details
      style="background-color: white;"
      :disabled="disabled">
      <template #append>
        <v-icon
          color="primary"
          :disabled="disabled"
          @click="onSearch()">
          mdi-magnify
        </v-icon>
      </template>
    </v-text-field>
  </form>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    search: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSearch () {
      if (this.disabled) {
        return
      }

      this.$emit('search')
    }
  }
}
</script>

<style scoped>

</style>
