import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class OrdersProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/orders', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/orders/${id}`)
  }

  getItemByOrderNo (id) {
    this.setHeader(getAuthToken())
    return this.get(`/orders/${id}/order-no`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/orders', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/orders/${payload.id}`, payload)
  }

  updateStateItemById (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/orders/${id}/state`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/orders/${id}`)
  }
}

export default OrdersProvider
