<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    nudge-width="360"
    max-width="360"
    max-height="50vh"
    offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        depressed
        block
        :ripple="false"
        style="width: fit-content; background-color: white;"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on">
        <span>
          ตัวกรอง
        </span>
        <v-icon>
          mdi-filter-variant
        </v-icon>
      </v-btn>
    </template>

    <v-card
      rounded="lg"
      class="pa-4">
      <span>
        {{ label }}
      </span>
      <v-divider class="my-1" />
      <div class="mb-4">
        <v-select
          v-model="query"
          :items="items"
          item-text="text"
          item-value="value"
          outlined
          dense
          placeholder="โปรดเลือก"
          hide-details
          required
          :disabled="disabled"
          class="mt-3">
          <template #selection="{ item }">
            <span :class="{ 'text-capitalize': capitalize }">
              {{ item.text }}
            </span>
          </template>
          <template #item="{ item }">
            <span :class="{ 'text-capitalize': capitalize }">
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: 'สถานะ'
    },
    items: {
      type: Array,
      default: () => [
        {
          text: 'ทั้งหมด',
          value: null
        }
      ]
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    query: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
